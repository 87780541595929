import { useEffect, useState } from 'react';
import axios from '../axios';
import toastService from '../services/toastService';
import { useParams } from 'react-router-dom';
import Loading from '../components/common/Loading';
import DefaultPageHeader from '../components/shared/DefaultPageHeader';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { KeyboardArrowDown } from '@mui/icons-material';
import { MenuItem, Select } from '@mui/material';

const EmployeeStats = () => {
  const { employeeId } = useParams();
  const [employee, setEmployee] = useState(null);
  const [employeeStats, setEmployeeStats] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalILS, setTotalILS] = useState(0);
  const [displayBy, setDisplayBy] = useState(4);

  useEffect(() => {
    getUserStats();
  }, [displayBy]);

  const getUserStats = () => {
    setLoading(true);
    axios({
      method: 'get',
      url: `/employee/${employeeId}/detailed?filter=${displayBy}`
    }).then((response) => {
      const data = response.data;
      let dataArr = [];
      let totalIlsPercentage = 0;
      let totalIlsCount = 0;

      for (const [key, value] of Object.entries(data?.charts)) {
        if (value.total_ils > 0) {
          totalIlsPercentage += value.total_ils;
          totalIlsCount += 1;
        }

        setTotalILS(
          totalIlsCount ? parseFloat(totalIlsPercentage / totalIlsCount).
            toFixed(2) : 0);

        dataArr.push({
          name: key,
          packages: value.total_delivery_packages +
            value.total_ecommerce_packages + value.total_pickup_packages,
          stops: value.total_delivery_stops + value.total_ecommerce_stops +
            value.total_pickup_stops,
          total_addition: value.total_addition,
          total_deduction: value.total_deduction,
          total_code_85: value.total_code_85,
          total_all_status_code_package: value.total_all_status_code_package,
          total_dna: value.total_dna,
          total_miss_pickup_window: value.total_miss_pickup_window,
          total_early_late_pickup: value.total_early_late_pickup,
          date: key
        });
      }

      setEmployeeStats(dataArr);

      setEmployee(data.employee);
    }).catch((err) =>
      toastService.error(err.response?.data?.message || 'Something went wrong')
    ).finally(() => {setLoading(false);});
  };

  const EmployeeILSCard = ({ employeeName, percentage }) => {
    const progressColor = percentage === 0
      ? '#ccc'
      : percentage >= 98 ? '#4caf50' : '#f44336';

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
          marginRight: '15px',
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          width: '200px',
          boxShadow: '0 2px 5px rgba(0,0,0,0.1)'
        }}
      >
        <h3 style={{ marginBottom: '10px' }}>{employeeName}</h3>
        <div
          style={{
            position: 'relative',
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            border: `10px solid ${progressColor}`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ fontSize: '20px', fontWeight: 'bold' }}>{percentage
            ? `${percentage}%`
            : 'N/A'}</span>
        </div>
      </div>
    );
  };

  const handleRangeChange = (e) => {
    setDisplayBy(e.target.value);
  };

  if (!employee) {
    return <Loading />;
  }

  console.log(employeeStats);

  return (
    <>
      {!loading && <DefaultPageHeader
        title={'Employee: ' + employee.first_name + ' ' + employee.last_name}
      />}
      <div className='overview-revenue'>

        <div className='payroll-top'>
          <Select
            className='payroll-table-select'
            value={displayBy}
            onChange={handleRangeChange}
            IconComponent={KeyboardArrowDown}>
            <MenuItem value={1}>week</MenuItem>
            <MenuItem value={4}>Month</MenuItem>
            <MenuItem value={24}>6 Month</MenuItem>
            <MenuItem value={48}>Year</MenuItem>
            <MenuItem value={999}>ALL</MenuItem>
          </Select>
        </div>

        {
          employeeStats ? (
            <>
              <div style={{ display: 'flex' }}>
                <EmployeeILSCard employeeName='ILS%' percentage={totalILS} />
                {/*<EmployeeILSCard employeeName='Employee Score'*/}
                {/*                 percentage={100} />*/}
              </div>

              <br /><br />
              <div className='chart-header'>
                <h4>Total Stops & Packages</h4>
              </div>

              <ResponsiveContainer width='100%' height={300}>
                <AreaChart
                  width={500}
                  height={400}
                  data={employeeStats}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' />
                  <YAxis />
                  <Tooltip />
                  <Area type='monotone' dataKey='packages'
                        stroke='#8884d8'
                        fill='rgba(66, 83, 240, 0.25)' />
                  <Area type='monotone' dataKey='stops'
                        stroke='#4253F0'
                        fill='rgba(66, 83, 240, 0.6)' />
                </AreaChart>
              </ResponsiveContainer>

              <div className='chart-header'>
                <h4>Total Bonuses & Reductions</h4>
              </div>
              <ResponsiveContainer width={'100%'} height={300}>
                <BarChart
                  data={employeeStats}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='name' />
                  <YAxis />
                  <Tooltip />
                  <Bar
                    dataKey='total_addition'
                    fill='#16AB6D'
                  />
                  <Bar
                    dataKey='total_deduction'
                    fill='#FF5F5E'
                  />
                </BarChart>
              </ResponsiveContainer>


              <div className='chart-header'>
                <h4>Total Codes</h4>
              </div>

              <div
                className='payroll-table payroll-data-table statement statement-height'>
                <table>
                  <thead>
                  <tr>
                    <th>
                      <div className='leaderboard-top-table-header-item'>
                        Date{' '}

                        {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="employee_name"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className='leaderboard-top-table-header-item'>
                        total_code_85{' '}

                        {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="employee_name"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className='leaderboard-top-table-header-item'>
                        total_all_status_code_package{' '}
                        {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="working_days"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className='leaderboard-top-table-header-item'>
                        total_dna{' '}
                        {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="perStop"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className='leaderboard-top-table-header-item'>
                        total_miss_pickup_window{' '}
                        {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="total_fuel"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                    <th>
                      <div className='leaderboard-top-table-header-item'>
                        total_early_late_pickup{' '}
                        {/*<TableSortArrowsBlock*/}
                        {/*  handleSortChange={handleSortChange}*/}
                        {/*  itemName="total_stops"*/}
                        {/*  activeSortedItem={sortItem}*/}
                        {/*/>*/}
                      </div>
                    </th>
                  </tr>
                  </thead>

                  <tbody>
                  {employeeStats.map((el) => (
                    <tr>
                      <td>{el.date}</td>
                      <td>{el.total_code_85}</td>
                      <td>{el.total_all_status_code_package}</td>
                      <td>{el.total_dna}</td>
                      <td>{el.total_miss_pickup_window}</td>
                      <td>{el.total_early_late_pickup}</td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </div>

            </>
          ) : null
        }
      </div>
    </>
  );
};

export default EmployeeStats;
